<template>
  <div style="padding: 20px">
    <div class="title">
      {{ $route.meta.title }}
    </div>
    <el-card :bordered="false" shadow="never" class="bg-class">
      <el-button type="primary" @click="addTask" class="mt14"
      v-auths="[`${$config.uniquePrefix}operate:scheduledtask:save`]"
        >添加定时任务</el-button
      >
      <el-table
        :data="tableData"
        v-loading="loading"
        border
        stripe
        class="ivu-mt"
      >
        <el-table-column label="标题" min-width="150">
          <template slot-scope="scope">
            <span>{{ scope.row.name }}</span>
          </template>
        </el-table-column>
        <el-table-column label="任务说明" min-width="130">
          <template slot-scope="scope">
            <span>{{ scope.row.content }}</span>
          </template>
        </el-table-column>
        <el-table-column label="执行周期" min-width="130">
          <template slot-scope="scope">
            <span>{{ taskTrip(scope.row) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="是否开启" min-width="130">
          <template slot-scope="scope">
            <el-switch
            v-auths="[`${$config.uniquePrefix}operate:scheduledtask:status`]"
              class="defineSwitch"
              :active-value="1"
              :inactive-value="0"
              v-model="scope.row.is_open"
              size="large"
              @change="handleChange(scope.row.id)"
              active-text="开启"
              inactive-text="关闭"
            >
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="100">
          <template slot-scope="scope">
            <a @click="edit(scope.row.id)"  v-auths="[`${$config.uniquePrefix}operate:scheduledtask:put`]">编辑</a>
            <a class="ml-min" @click="handleDelete(scope.row.id)" v-auths="[`${$config.uniquePrefix}operate:scheduledtask:delete`]">删除</a>
          </template>
        </el-table-column>
      </el-table>
      <div class="acea-row row-right page">
        <!-- <el-pagination
        :total="total"
        :page.sync="page"
        :limit.sync="limit"
        @size-change="fn(a, b)"
        @current-change="fn"
      /> -->
      </div>
      <creatTask ref="addTask" @submitAsk="getList"></creatTask>
    </el-card>
  </div>
</template>

<script>
import { scheduledlist, deletingtask, updatedingtask } from "@/api/operate";
import creatTask from "./createModal.vue";
export default {
  name: "system_crontab",
  components: { creatTask },
  data() {
    return {
      loading: false,
      tableData: [],
      page: 1,
      limit: 15,
      total: 1,
    };
  },
  created() {
    this.getList();
  },
  methods: {
    taskTrip(row) {
      switch (row.type) {
        case 1:
          return `每隔${row.second || 0}秒执行一次`;
        case 2:
          return `每隔${row.minute || 0}分钟执行一次`;
        case 3:
          return `每隔${row.hour || 0}小时执行一次`;
        case 4:
          return `每隔${row.day || 0}天执行一次`;
        case 5:
          return `每天${row.hour || 0}时${row.minute || 0}分${
            row.second || 0
          }秒执行一次`;
        case 6:
          return `每个星期${row.week || 0}的${row.hour || 0}时${
            row.minute || 0
          }分${row.second || 0}秒执行一次`;
        case 7:
          return `每月${row.day || 0}日的${row.hour || 0}时${
            row.minute || 0
          }分${row.second || 0}秒执行一次`;
      }
    },
    fn() {
      //页码改变生成的页面
      console.log(this.page, this.limit);
      console.log(this.total);
    },
    // 列表
    getList() {
      this.loading = true;
      scheduledlist({
        page: this.page,
        limit: this.limit,
      })
        .then((res) => {
          if (res.code == 200) {
            this.loading = false;
            let { count, list } = res.data;
            this.total = count;
            this.tableData = list;
          } else {
            this.$message.error(res.msg);
          }
          this.$refs.addTask.modal = false;
        })
        .catch((res) => {
          this.loading = false;
          this.$message.error(res.msg);
        });
    },
    addTask() {
      this.$refs.addTask.modal = true;
    },
    edit(id) {
      //拿到组件实例触发timeInfo方法
      this.$refs.addTask.timerInfo(id);
    },
    // 删除
    handleDelete(id) {
      let that = this;
      this.$utils.confirm(
        "确定要删除吗？",
        function () {
          deletingtask(id).then((res) => {
            if (res.code == 200) {
              that.$message.success(res.msg);
              that.getList();
            } else {
              that.$message.error(res.msg);
            }
          });
        },
        "确定",
        "warning"
      );
    },
    // 是否开启
    handleChange(id) {
      updatedingtask(id)
        .then((res) => {
          this.$message.success(res.msg);
          this.getList();
        })
        .catch((res) => {
          this.$message.error(res.msg);
        });
    },
  },
};
</script>

<style  scoped>
.bg-class {
  background-color: #f0f0f0;
}
.ivu-mt {
  padding: 20px 0;
  background-color: #f0f0f0;
  border-radius: 10px;
}
::v-deep .el-card__body {
  padding: 20px 0;
}
.ml-min{
  margin-left: 5px;
}
</style>
